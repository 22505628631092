import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgCoach from "../images/coach-blue-blur.jpg"
import terms from "../docs/shuttleid-terms-of-use-passengers,-purchasers-and-other-users-updated-august-2020.pdf";
import termsSimplePackage from "../docs/shuttleid-simple-package-terms-of-use-transport-operator-updated-august-2020.pdf";
import termsCompletePackage from "../docs/shuttleid-complete-package-terms-of-use-transport-operator-updated-august-2020.pdf";
import termsBodsPackage from "../docs/shuttleid-bus-open-data-package-terms-2021-03-22.pdf";
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero from "../components/hero-standard"
import Nav from "../components/nav"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Terms" />
        <Nav />
        <div>
            <div>

              <div>

                <Hero h1="Terms" h2="The important legal stuff" />

                <div className="article">
                  <div className='article-inner' style={{ textAlign: 'center' }}>
                    <p style={{ fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>Access the ShuttleID terms of use below:</p>
                    <p><a href={terms}>Terms of Use - Passengers and Purchasers</a></p>
                    <p><a href={termsCompletePackage}>Terms of Use - Transport Operators (Complete Package)</a></p>
                    <p><a href={termsSimplePackage}>Terms of Use - Transport Operators (Simple Package)</a></p>
                    <p><a href={termsBodsPackage}>Terms of Use - Transport Operators (Bus Open Data Package)</a></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
